// Buttons
.button,
input[type='button'],
input[type='submit'],
input[type='reset'],
button {
  padding: 1rem 2.5rem;
  color: $color-workflow-text;
  background: $color-workflow-bg-secondary;
  border-radius: 6px;
  border: 1px solid transparent;
  font-size: 1.2rem;
  text-align: center;
  transition: opacity 0.2s ease;
  text-decoration: none;
  transform: scale(1);
  display: inline-block;
  cursor: pointer;

  &.positive {
    background: $color-workflow-positive;
    color: $color-workflow-white;
  }

  &.negative {
    background: $color-workflow-negative;
    color: $color-workflow-white;
  }

  &.dark {
    background-color: $color-workflow-darkgrey;
    color: $color-workflow-white;
  }

  &.error {
    background-color: $color-workflow-error;
    color: $color-workflow-white;
  }

  &.success {
    background-color: $color-workflow-success;
    color: $color-workflow-white;
  }

  &.active {
    background-color: $color-workflow-active;
    color: $color-workflow-white;
  }

  &.rounded {
    border-radius: 88px !important;
  }

  &.sm {
    padding: 0 1.5rem;
    height: 2em;
  }

  &.cp-ok-button-class {
    background: $color-workflow-positive;
    color: $color-workflow-white;
  }
}

.grouped {
  display: flex;
}

.grouped > *:not(:last-child) {
  margin-right: 16px;
}

.grouped.gapless > * {
  margin: 0 0 0 -1px !important;
  border-radius: 0 !important;
}
.grouped.gapless > *:first-child {
  margin: 0 !important;
  border-radius: 4px 0 0 4px !important;
}

.grouped.gapless > *:last-child {
  border-radius: 0 4px 4px 0 !important;
}

.button + .button {
  margin-left: 1rem;
}

.button:hover,
[type='button']:hover,
[type='reset']:hover,
[type='submit']:hover,
button:hover {
  opacity: 0.8;
}

.button:active,
[type='button']:active,
[type='reset']:active,
[type='submit']:active,
button:active {
  transform: scale(0.98);
}

input:disabled,
button:disabled,
input:disabled:hover,
button:disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
}

// Overrides a font weight setting in the ios webkit user agent stylesheet to avoid causing bold
/*
input[type="submit"], 
input[type="submit"]:active, 
button[type="submit"], 
button[type="submit"]:active, 
button[type="menu"], 
button[type="menu"]:active {
  background-color: -apple-system-blue;
  color: white;
  font-weight: bold;
  }
*/
input[type='submit'],
input[type='submit']:active,
button[type='submit'],
button[type='submit']:active,
button[type='menu'],
button[type='menu']:active {
  font-weight: unset;
}
